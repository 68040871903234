const mainTypes = {
    WRITE_WORDS: 'WRITE_WORDS',
    SHUFFLE_WORDS: 'SHUFFLE_WORDS',
    SIGN_IN: 'SIGN_IN',
    GET_LISTS: 'GET_LISTS',
    SIGN_IN_ERROR: 'SIGN_IN_ERROR',
    GET_LIST: 'GET_LIST'

};

export default mainTypes;